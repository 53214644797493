export const MIGRATED_CONSUMERS = [
    "0xA35Ddeb78500A7f1Ac4ef89C2688F7e6D4705A16",
    "0xEE2D3550B1d64C45A91baFFaFACE0F59EA8155f4",
    "0xEE2D3550B1d64C45A91baFFaFACE0F59EA8155f4",
    "0x9Ac820dACE4E2f60A534A49410517A3B5F96Ef9e",
    "0x6De12aefa3f2b716741d8D187D7ee05CBA1308Eb",
    "0x6De12aefa3f2b716741d8D187D7ee05CBA1308Eb",
    "0x6De12aefa3f2b716741d8D187D7ee05CBA1308Eb",
    "0x20bbE2540e76C951e47619B33841BDc76BAFd3a4",
    "0x20bbE2540e76C951e47619B33841BDc76BAFd3a4",
    "0x5044281e284Ce117A6C11653e58cD309E5633Cf3",
    "0xeA465E4c30eDc005f720d5d181F8d98930Cc0eec",
    "0x3e83bcEf10082EaC00346CFCD53886b9Ef3D8BbE",
    "0x3e83bcEf10082EaC00346CFCD53886b9Ef3D8BbE",
    "0x3e83bcEf10082EaC00346CFCD53886b9Ef3D8BbE",
    "0x3e83bcEf10082EaC00346CFCD53886b9Ef3D8BbE",
    "0x3e83bcEf10082EaC00346CFCD53886b9Ef3D8BbE",
    "0x3e83bcEf10082EaC00346CFCD53886b9Ef3D8BbE",
    "0x3e83bcEf10082EaC00346CFCD53886b9Ef3D8BbE",
    "0x3e83bcEf10082EaC00346CFCD53886b9Ef3D8BbE",
    "0x3e83bcEf10082EaC00346CFCD53886b9Ef3D8BbE",
    "0x3e83bcEf10082EaC00346CFCD53886b9Ef3D8BbE",
    "0x3e83bcEf10082EaC00346CFCD53886b9Ef3D8BbE",
    "0x3e83bcEf10082EaC00346CFCD53886b9Ef3D8BbE",
    "0x3e83bcEf10082EaC00346CFCD53886b9Ef3D8BbE",
    "0xC66ccE2a97a6dB6117Abb6970688486EAe53C285",
    "0x2f26ae0568BD651318849c18F9Bc6776629d3bDA",
    "0xDAaB89ab6F5884555a3E17fD17442CA520A4E0e4",
    "0xBDe609E32Fc9bbEA143f4F190e438dAD4c96C1c0",
    "0xE252d48C95821F9fc33052bd40280C817dD153e0",
    "0x7Af7cA67AA827F58e0659C52a641aeE55a43B535",
    "0x144eA925D68A3c3803a5ca963d8e880b268EaEbD",
    "0x4F80DB384eb7C2F529274ccA0Afb4cD9882DCAa0",
    "0xEE2D3550B1d64C45A91baFFaFACE0F59EA8155f4",
    "0x5debb97a6Cc1Fdf686a3C6aA804a623a21deD73c",
    "0xf4d95fCd487438721423ceb9A670eA371151E12B",
    "0x90cfC0E17855C195FB307c16680d511469C483D4",
    "0xBE732bdD5b1218A4Da23C27F1a0dF8be228AF13C",
    "0xc88530038d3995601946985B40E29775eF1362D2",
    "0xc88530038d3995601946985B40E29775eF1362D2",
    "0xc88530038d3995601946985B40E29775eF1362D2",
    "0x94685429E684486376a9aB393910f67973c4A274",
    "0x49fc00b3ffD512a4E1d24a149e6bb86e727d074e",
    "0x5f73913BeBBe9658AA411084c0eF760332b98692",
    "0x5f73913BeBBe9658AA411084c0eF760332b98692",
    "0x77A6C84cD8ca15841Dcb6Bb3640C5E0cCe7763A4",
    "0xdFEAC7c6e78b60ef4014Ca3CD55B65b8A9558F2E",
    "0x7E4Ee449AFF87b237bAe6ACB83Fadf795bDba4C6",
    "0x68009EE6eABE12F514DCC9dcda4d3bd9Fb3131A2",
    "0x72CC82900823c29310985dECDD607A68f9Bc8418",
    "0xD31E05B7dfFd10E812df2E59824050a4E38F9736",
    "0x20425c413Bb87023D53f0Cdb4616149656721219",
    "0x6712C2B24acCe7a7432eBcd7C11989a53f23C7F5",
    "0xd8EA9100d0c3C4Ce66174eFd4AF0C88531aBD684",
    "0x3109cBCc077Bc1471CE2044219e2f7c735656678",
    "0x23983CDdCCE43aedd1c032300032dd6abE8dB997",
    "0x3E5E7A7b5d38f34d65d6E0A050E9667e14F5E082",
    "0x18C6A47AcA1c6a237e53eD2fc3a8fB392c97169b",
    "0x18C6A47AcA1c6a237e53eD2fc3a8fB392c97169b",
    "0x23983CDdCCE43aedd1c032300032dd6abE8dB997",
    "0x750302D33d7Cb685e4659f43D709A7C4f299B472",
    "0x97F80b0026Afc165888eb3608874C902b6Aed3d6",
    "0x97F80b0026Afc165888eb3608874C902b6Aed3d6",
];
    
export const MIGRATED_DISTRIBUTORS = [
    "0x7309172EbD55bc672529B2FbF219E528eC143d6b",
    "0x7Ae83077561Ef02c279e0F1Dd6Cca9d85e037829",
    "0x2D79F27DE1a358ca90804838A29dF2A65E35eA68",
    "0xDfd53F6eD2FB24aDd42De59dAb002Bac2a97f5d9",
    "0x7E4Ee449AFF87b237bAe6ACB83Fadf795bDba4C6",
    "0x2c4a62aa7100b65400e78b499d5c4e03b602528E",
    "0xe33E3a7F44099D70F4498346686ed9435Ff225E2",
    "0x7c33D842700BcaC9dC6B020f9C0B133cA9458F7E",
    "0xdf5BeEbf9B3B1817a4f3EA68809438b510120CF7",
    "0x7E4Ee449AFF87b237bAe6ACB83Fadf795bDba4C6",
];
    
export const MIGRATED_MASTERBREWERS = [
    "0x93eC0E6D689dD8b9AD5872520Ae97381C9C6189D",
    "0x7E4Ee449AFF87b237bAe6ACB83Fadf795bDba4C6",
];